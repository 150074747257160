import React, { } from "react";
import { Paper, Box, Container } from "@mui/material";
import { Link } from "react-router-dom";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ListingsBanner = () => {
  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "12px",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 7000,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    pauseOnHover: true,
    adaptiveHeight: true,
  };

  return (
    <Container maxWidth="sm" sx={{ px: 0 }}>

      <Slider {...settings}>
        <Box
          sx={{
            paddingY: 2,
            paddingX: '4px',
          }}
        >
          <Link to="https://tutuumarket.ru/uns/stroymaterialy" target="_blank">
            <Paper
              elevation={1}
              sx={{ padding: 0, borderRadius: 3 }}
            >
              <img src="/static/client/1/tutuu.png" alt="Стройматериалы" style={{ width: "100%", borderRadius: 12 }} />
            </Paper>
          </Link>
        </Box>
        <Box
          sx={{
            paddingY: 2,
            paddingX: '4px',
          }}
        >
          <Link to={`https://wa.me/79627316757?text=Из%20Uustar.%20`} target="_blank">
            <Paper
              elevation={1}
              sx={{ padding: 0, borderRadius: 3 }}
            >
              <img src="/static/client/1/noj.png" alt="Якутские ножи ручной работы" style={{ width: "100%", borderRadius: 12 }} />
            </Paper>
          </Link>
        </Box>
        <Box
          sx={{
            paddingY: 2,
            paddingX: '4px',
          }}
        >
          <Link to="https://silent-horizons.com/" target="_blank">
            <Paper
              elevation={1}
              sx={{ padding: 0, borderRadius: 3 }}
            >
              <img src="/static/client/1/inyakutia.png" alt="инЯкутия" style={{ width: "100%", borderRadius: 12 }} />
            </Paper>
          </Link>
        </Box>
        <Box
          sx={{
            paddingY: 2,
            paddingX: '4px',
          }}
        >
          <Link to="https://tutuumarket.ru/suuyar/" target="_blank">
            <Paper
              elevation={1}
              sx={{ padding: 0, borderRadius: 3 }}
            >
              <img src="/static/client/1/suuyar.png" alt="Сууйар" style={{ width: "100%", borderRadius: 12 }} />
            </Paper>
          </Link>
        </Box>
      </Slider>
    </Container>
  );
};

export default ListingsBanner;
