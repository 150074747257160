import Pushy from 'pushy-sdk-web';
import api from './api';

export const enablePersonalNotifications = async ({ onAuthFail }) => {
  if (!Pushy.isRegistered()) {
    await register();
  }

  const deviceToken = localStorage.getItem('pushyToken');

  try {
    await api.post('/devicetokens', { token: deviceToken }, { skipAuthInterceptor: true });

    localStorage.setItem('isPersonalNotificationsEnabled', 'true');
  } catch (error) {
    if (error.response && error.response.status === 401) {
      onAuthFail && onAuthFail();

      return;
    }

    throw error;
  }
};

export const refreshToken = async () => {
  if (Pushy.isRegistered()) {
    const deviceToken1 = localStorage.getItem('pushyToken');

    try {
      await register();
    }
    catch (error) {
      console.error('Failed to refresh the device token', error);
      return;
    }

    const deviceToken2 = localStorage.getItem('pushyToken');

    if (deviceToken1 !== deviceToken2) {
      await resubscribeToTopics();

      if (isPersonalNotificationsEnabled()) {
        await disablePersonalNotifications();
        await enablePersonalNotifications({});
      }
    }
  }
};

const register = async () => {
  try {
    const token = await Pushy.register({ appId: process.env.REACT_APP_PUSHY_APP_ID });
    return token;
  } catch (error) {
    console.error('Failed to register the device token', error);
    localStorage.setItem('isPersonalNotificationsEnabled', 'false');
    localStorage.setItem('topicsState', JSON.stringify(topicsState));
    throw error;
  }
};

export const disablePersonalNotifications = async () => {
  const deviceToken = localStorage.getItem('pushyToken');

  if (deviceToken) {
    await api.delete(`/devicetokens/${deviceToken}`, {
      skipAuthInterceptor: true,
      validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
    });

    localStorage.setItem('isPersonalNotificationsEnabled', 'false');
  }
};

export const isPersonalNotificationsEnabled = () => localStorage.getItem('isPersonalNotificationsEnabled') === 'true';

const topicsState = {
  'cat-1': false,
  'cat-2': false,
  'cat-3': false,
  'cat-4': false,
  'cat-5': false,
  'cat-6': false,
  'cat-7': false,
  'cat-8': false,
  'cat-9': false,
  'cat-10': false,
  'cat-11': false,
  'cat-12': false,
  'cat-13': false,
  'cat-14': false,
  'cat-15': false,
  'cat-16': false,
  'cat-17': false,
  'cat-18': false,
  'cat-19': false,
  'cat-20': false,
  'cat-21': false,
  'cat-22': false,
  'cat-23': false,
  'cat-24': false,
  'cat-25': false,
  'cat-26': false,
  'cat-27': false,
  'cat-28': false,
  'cat-29': false,
  'cat-30': false,
  'cat-31': false,
  'cat-32': false,
  'cat-33': false,
  'cat-34': false,
  'cat-35': false,
  'cat-36': false,
  'cat-37': false,
  'cat-38': false,
  'cat-39': false,
  'cat-40': false,
  'cat-41': false,
  'cat-42': false,
  'cat-43': false,
  'cat-44': false,
  'cat-45': false,
  'cat-46': false,
  'cat-47': false,
  'cat-48': false,
};

export const subscribeToTopic = async (updatedTopics) => {
  const currentState = JSON.parse(localStorage.getItem('topicsState')) || topicsState;

  const topicsToSubscribe = Object.keys(currentState).filter(topic => updatedTopics[topic] === true && currentState[topic] !== true);
  const topicsToUnsubscribe = Object.keys(currentState).filter(topic => updatedTopics[topic] !== true && currentState[topic] === true);

  if (!Pushy.isRegistered()) {
    await register();
  }

  if (topicsToSubscribe.length > 0)
    await Pushy.subscribe(topicsToSubscribe);

  if (topicsToUnsubscribe.length > 0)
    await Pushy.unsubscribe(topicsToUnsubscribe);

  Object.assign(currentState, updatedTopics);

  localStorage.setItem('topicsState', JSON.stringify(currentState));
};

const resubscribeToTopics = async () => {
  const currentState = JSON.parse(localStorage.getItem('topicsState')) || topicsState;

  const topicsToSubscribe = Object.keys(currentState).filter(topic => currentState[topic] === true);
  const topicsToUnsubscribe = Object.keys(currentState).filter(topic => currentState[topic] !== true);

  if (topicsToSubscribe.length > 0)
    await Pushy.subscribe(topicsToSubscribe);

  if (topicsToUnsubscribe.length > 0)
    await Pushy.unsubscribe(topicsToUnsubscribe);
};

export const getSubscribedTopics = () => {
  return JSON.parse(localStorage.getItem('topicsState')) || topicsState;
};
