// pages/Login.js
import React, { useEffect, useState } from 'react';
import PhoneInput from '../components/PhoneInput';
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Link,
  Alert,
  TextField,
  Button,
  Stack,
  Avatar,
  // IconButton,
  LinearProgress
} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { enablePersonalNotifications } from '../services/pushy';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
// import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { login } from '../slices/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import api from '../services/api';

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState(null);
  const [name, setName] = useState('');
  const [photoBlob, setPhotoBlob] = useState(null); // Store the resized image as a Blob
  const [imageSrc, setImageSrc] = useState(null);
  const [searchParams] = useSearchParams();
  const to = searchParams.get('to');
  const [activeStep, setActiveStep] = React.useState(0);

  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIos = /iphone|ipad|ipod/.test(userAgent);
  const isInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;

  const [isIframe, setIsIframe] = useState(false);

  useEffect(() => {
    setIsIframe(window.self !== window.top);
  }, []);

  // const [installOnIos, setInstallOnIos] = useState(isIos && !isInStandaloneMode);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const handleBack = () => {
    setError(null);

    if (activeStep === 0) {
      navigate('/', { replace: true });
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const navigate = useNavigate();
  const phoneNumberNormalized = (phone) => phone.replace(/\D/g, '');

  const handleSendCode = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await api.post('/auth/code', {
        phoneNumber: phoneNumberNormalized(phoneNumber),
      });

      setActiveStep(1);
    } catch (error) {
      setError(error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const response = await api.post('/auth/token', {
        phoneNumber: phoneNumberNormalized(phoneNumber),
        otp: verificationCode,
      });

      const data = response.data;
      dispatch(login(data));

      if (data.name) {
        goToLastStep();
      } else {
        setActiveStep(2);
      }

    } catch (error) {
      setError(error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  function goToLastStep() {
    if (isIos && !isInStandaloneMode) {
      navigate(to ? to : '/', { replace: true });
    } else {
      setActiveStep(3);
    }
  }

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      if (!token) {
        setActiveStep(0);
        return;
      }

      const formData = new FormData();

      formData.append("name", name);

      if (photoBlob) {
        formData.append("photo", photoBlob);
      }

      await api.put('/users/my', formData);

      goToLastStep();
    } catch (error) {
      setError(error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEnableNotifications = async () => {
    setError(null);
    setLoading(true);
    try {
      if (!token) {
        setActiveStep(0);
        return;
      }

      await enablePersonalNotifications({ onAuthFail: () => setActiveStep(0) });

      navigate(to ? to : '/', { replace: true });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          // Create a canvas element to resize the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height (in pixels)
          const maxWidth = 512;
          const maxHeight = 512;

          let width = img.width;
          let height = img.height;

          // Calculate new width and height while maintaining aspect ratio
          if (width > height) {
            if (width > maxWidth) {
              height = Math.round((height * maxWidth) / width);
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width = Math.round((width * maxHeight) / height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          // Draw the image on the canvas
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (for uploading)
          canvas.toBlob(
            (blob) => {
              setImageSrc(URL.createObjectURL(blob)); // Preview resized image
              setPhotoBlob(blob); // Store the Blob to upload later
            },
            "image/jpeg"
          );
        };
      };
    }
  };

  // if (installOnIos)
  //   return (
  //     <Container maxWidth="sm" sx={{ mt: 1 }}>
  //       <Stack spacing={1} justifyContent="center" minHeight='80vh'>

  //         <Stack direction='row' spacing={1} alignItems='baseline'>
  //           <IconButton color='inherit' size='large' onClick={() => navigate('/', { replace: true })} aria-label="go back">
  //             <ArrowBackIosNewIcon />
  //           </IconButton>
  //           <Typography variant="h5">Установите приложение</Typography>
  //         </Stack>

  //         <Typography variant='body1'>
  //           Чтобы получать уведомления, добавьте приложение на главный экран.
  //         </Typography>

  //         <Typography variant="body2">Нажмите "Поделиться":</Typography>
  //         <img src="/ios-share.jpg" alt="iOS Share" style={{ width: '100%', marginBottom: '8px' }} />
  //         <Typography variant="body2">Выберите "На экран &laquo;Домой&raquo;":</Typography>
  //         <img src="/ios-add-to-home.jpg" alt="iOS Add to Home" style={{ width: '100%', marginBottom: '8px' }} />
  //       </Stack>

  //       <Button fullWidth variant='contained' onClick={() => setInstallOnIos(false)} endIcon={<KeyboardArrowRight />}>
  //         Продолжить без уведомлений
  //       </Button>
  //     </Container>
  //   );

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      {activeStep === 0 && (
        <Stack spacing={1} justifyContent="center" minHeight='80vh'>
          <Typography variant="h5">Введите номер телефона</Typography>
          <Alert severity="info" >
            Если вы предоставляете услугу, <Link href={process.env.REACT_APP_MASTER_URL}>переключитесь в режим мастера</Link>.
          </Alert>
          <form onSubmit={handleSendCode}>
            <PhoneInput
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {error && <Alert sx={{ mb: 1 }} severity="error">{error}</Alert>}
            <Button fullWidth variant='contained' type="submit" disabled={loading} endIcon={<KeyboardArrowRight />}>
              Продолжить
            </Button>
          </form>
          <Typography variant='body2'>
            На сайте используются куки-файлы. Нажимая на кнопку Продолжить, вы соглашаетесь с&nbsp;
            <Link component={RouterLink} to="/privacy">Политикой конфиденциальности</Link>.
          </Typography>
        </Stack>
      )}

      {activeStep === 1 && (
        <Stack spacing={1} justifyContent="center" minHeight='80vh'>
          <Typography variant="h5">Введите CMC-код</Typography>
          <form onSubmit={handleVerifyCode}>
            <TextField
              value={verificationCode}
              type="number"
              label="Код"
              required
              fullWidth
              margin="normal"
              onChange={(e) => setVerificationCode(e.target.valueAsNumber)} />

            {error && <Alert sx={{ mb: 1 }} severity="error">{error}</Alert>}

            <Stack spacing={1} direction='row'>
              <Button variant='outlined' onClick={handleBack} startIcon={<KeyboardArrowLeft />}>
                Назад
              </Button>
              <Button variant='contained' type="submit" disabled={loading} endIcon={<KeyboardArrowRight />}>
                Продолжить
              </Button>
            </Stack>
          </form>

          <Typography variant='caption' alignContent='center' fullWidth>
            <Link href="https://t.me/profisfera_ru" target="_blank">
              Написать в поддержку
            </Link>
          </Typography>
        </Stack>
      )}

      {activeStep === 2 && (
        <Stack spacing={1} justifyContent="center" minHeight='80vh'>
          <Typography variant="h5">Введите имя</Typography>
          <form onSubmit={handleProfileUpdate}>
            <Stack alignItems='center' spacing={1}>
              <Avatar
                src={imageSrc}
                sx={{ bgcolor: 'primary.light', width: 100, height: 100 }}
              />
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                endIcon={<CloudUploadIcon />}>
                Загрузить фотографию
                <VisuallyHiddenInput type="file" accept="image/*" onChange={handleFileChange} />
              </Button>
            </Stack>
            <TextField
              value={name}
              margin="normal"
              required
              fullWidth
              label="Имя"
              onChange={(e) => setName(e.target.value)} />
            {error && <Alert sx={{ mb: 1 }} severity="error">{error}</Alert>}

            <Button fullWidth variant='contained' type="submit" disabled={loading} endIcon={<KeyboardArrowRight />}>
              Продолжить
            </Button>
          </form>
        </Stack>
      )}

      {activeStep === 3 && (
        <Stack spacing={2} justifyContent="center" minHeight='80vh'>
          {error && ((error.includes('Add to Home') || isIframe)
            ? <Alert severity="warning">
              Для получения уведомлений,<br /><Link component={RouterLink} target="_blank" to="/install-app">установите приложение на домашний экран</Link>.</Alert>
            : (error.includes('permission')
              ? (isIos
                ? <Alert severity="warning">Разрешите приложению получать уведомления в настройках системы.</Alert>
                : <Alert severity="warning">Разрешите уведомления в настройках браузера.</Alert>)
              : <Alert severity="error">{error}</Alert>))}
          <Button fullWidth variant='contained' onClick={() => handleEnableNotifications()} disabled={loading}
            endIcon={<NotificationsIcon />}>
            Включить уведомления
          </Button>
          <Button fullWidth variant='outlined' onClick={() => navigate(to ? to : '/', { replace: true })} disabled={loading}
            endIcon={<NotificationsOffIcon />}>
            Продолжить без уведомлений
          </Button>
        </Stack>
      )}

      <Box sx={{ height: 8 }} />

      {loading && <LinearProgress />}

    </Container>
  );
};

export default Login;
