import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Link,
  Stack,
  Typography
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchListing } from '../slices/listingsSlice';
import api from '../services/api';
import Mark from "./Mark";
import { useState } from "react";

const Offers = ({ listing }) => {
  const { userId } = useSelector((state) => state.auth);
  const [error, setError] = useState(null);

  const handleAcceptOffer = async (offerId) => {
    setError(null);

    try {
      await api.put(`/offers/${offerId}/accept`);

      dispatch(fetchListing({ id: listing.id }));
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };

  const contact = (masterId, src) => {
    api.post('/masters/contact?masterId=' + masterId + '&source=' + src);
  }

  function offersComparator(a, b) {
    if (a.markTypeIds.includes(1) && !b.markTypeIds.includes(1)) {
      return -1;
    }

    if (!a.markTypeIds.includes(1) && b.markTypeIds.includes(1)) {
      return 1;
    }

    if (a.markTypeIds.includes(2) && !b.markTypeIds.includes(2)) {
      return -1;
    }

    if (!a.markTypeIds.includes(2) && b.markTypeIds.includes(2)) {
      return 1;
    }

    return new Date(a.createdAt) - new Date(b.createdAt);
  }

  const isOpen = !listing.hasAcceptedOffer && !listing.isClosed;
  const acceptedOffer = listing.offers?.find(o => o.isAccepted === true);

  const dispatch = useDispatch();
  return (
    <>
      <Stack sx={{ pt: 1 }} direction='row' spacing={1} alignItems='baseline'>
        <Typography variant="h5" gutterBottom >Предложения</Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={() => dispatch(fetchListing({ id: listing.id }))} aria-label="refresh">
          <RefreshIcon />
        </IconButton>
      </Stack>

      {isOpen && userId !== listing.creatorId && <Alert severity="info" >
        <Typography>Чтобы оставить предложение, <Link href={process.env.REACT_APP_MASTER_URL + '/listing/' + listing.id}>переключитесь в режим мастера</Link>.</Typography>
      </Alert>}

      {acceptedOffer ? (
        <Card key={acceptedOffer.id}>
          <CardHeader
            avatar={<Link component={RouterLink} to={`/master/${acceptedOffer.creatorId}?from=offer`}>
              <Avatar
                sx={{ bgcolor: 'primary.light' }}
                src={acceptedOffer.photoUrl}
                alt={`${acceptedOffer.name} ${acceptedOffer.lastName || ''}`} />
            </Link>}

            title={<Link component={RouterLink} to={`/master/${acceptedOffer.creatorId}?from=offer`}>{acceptedOffer.name} {acceptedOffer.lastName || ''}</Link>}
            subheader={acceptedOffer.ratingsCount > 0
              ? `Отзывов: ${acceptedOffer.ratingsCount}`
              : 'Нет отзывов'
            }
            action={<Typography variant="caption" color="text.secondary">
              {new Date(acceptedOffer.createdAt).toLocaleDateString()}
            </Typography>}
          />
          <CardContent>
            {(acceptedOffer.price && <Typography variant="subtitle1">{acceptedOffer.price} руб.</Typography>)}
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{acceptedOffer.description}</Typography>
            {acceptedOffer.phoneNumber &&
              <Typography>
                <Link href={`tel:${acceptedOffer.phoneNumber}`} sx={{ mr: 1 }} variant="body1">+{acceptedOffer.phoneNumber}</Link>
                /
                <Link href={`https://wa.me/${acceptedOffer.phoneNumber}?text=Из%20https://uustar.ru/app/listing/${listing.id}.%20`} target="_blank" variant="body1" sx={{ m: 1 }}>WhatsApp</Link>
                /
                <Link href={`https://t.me/+${acceptedOffer.phoneNumber}?text=Из%20https://uustar.ru/app/listing/${listing.id}.%20`} target="_blank" variant="body1" sx={{ m: 1 }}>Telegram</Link>
              </Typography>}
            <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
              {acceptedOffer.markTypeIds.map((markTypeId) => (
                <Mark key={markTypeId} markTypeId={markTypeId} />
              ))}
            </Stack>
            {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}
          </CardContent>
        </Card>
      ) : (
        listing.offers?.length > 0 ? (
          [...listing.offers].sort(offersComparator).map((offer) => (
            <Card key={offer.id}>
              <CardHeader
                avatar={<Link component={RouterLink} to={`/master/${offer.creatorId}?from=offer`}>
                  <Avatar
                    sx={{ bgcolor: 'primary.light' }}
                    src={offer.photoUrl}
                    alt={`${offer.name} ${offer.lastName || ''}`} />
                </Link>}

                title={<Link component={RouterLink} to={`/master/${offer.creatorId}?from=offer`}>{offer.name} {offer.lastName || ''}</Link>}
                subheader={offer.ratingsCount > 0
                  ? `Отзывов: ${offer.ratingsCount}`
                  : 'Нет отзывов'
                }
                action={<Typography variant="caption" color="text.secondary">
                  {new Date(offer.createdAt).toLocaleDateString()}
                </Typography>}
              />
              <CardContent>
                {(offer.price && <Typography variant="subtitle1">{offer.price} руб.</Typography>)}
                <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>{offer.description}</Typography>
                {offer.phoneNumber &&
                  <Typography>
                    <Link onClick={() => contact(offer.creatorId, 'offer_phone')} href={`tel:${offer.phoneNumber}`} sx={{ mr: 1 }} variant="body1">+{offer.phoneNumber}</Link>
                    /
                    <Link onClick={() => contact(offer.creatorId, 'offer_wa')} href={`https://wa.me/${offer.phoneNumber}?text=Из%20https://uustar.ru/app/listing/${listing.id}.%20`} target="_blank" variant="body1" sx={{ m: 1 }}>WhatsApp</Link>
                    /
                    <Link onClick={() => contact(offer.creatorId, 'offer_tg')} href={`https://t.me/+${offer.phoneNumber}?text=Из%20https://uustar.ru/app/listing/${listing.id}.%20`} target="_blank" variant="body1" sx={{ m: 1 }}>Telegram</Link>
                  </Typography>}
                <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
                  {offer.markTypeIds.map((markTypeId) => (
                    <Mark key={markTypeId} markTypeId={markTypeId} />
                  ))}
                </Stack>
                {error && <Alert sx={{ mt: 1 }} severity="error">{error}</Alert>}
              </CardContent>
              <CardActions>
                {listing.creatorId === userId && ((
                  !listing.isClosed &&
                  !listing.hasAcceptedOffer) &&
                  <Button variant='contained' onClick={() => handleAcceptOffer(offer.id)}>Принять</Button>)}
                <Box sx={{ flexGrow: 1 }} />
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography>Предложений пока нет</Typography>
        )
      )}
    </>
  );
}

export default Offers;