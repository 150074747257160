// pages/User.js
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Avatar,
  Container,
  Alert,
  CardHeader,
  IconButton,
  LinearProgress,
  CardActions,
  Link,
  CardMedia,
  Box,
} from '@mui/material';
import Slider from 'react-slick';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ShareIcon from '@mui/icons-material/Share';
import api from '../services/api';
import Mark from '../components/Mark';

const Master = () => {
  const { masterId } = useParams();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const fetchRatings = async () => {
      try {
        const response = await api.get(`/masters/${masterId}?from=${searchParams.get('from') || ''}`);

        setData(response.data);
      } catch (error) {
        setError(error.response?.data || error.message);
      }
    };

    if (masterId) {
      fetchRatings();
    }
  }, [masterId]);

  const handleShare = () => {
    const url = process.env.REACT_APP_CLIENT_URL + "/master/" + masterId;

    if (navigator.share) {
      navigator.share({
        title: `${data?.name} ${data?.lastName || ''}`,
        url: url,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      navigator.clipboard?.writeText(url).then(() => {
        alert('Ссылка скопирована в буфер обмена');
      }) || setError('Не удалось скопировать ссылку в буфер обмена');
    }
  };

  const contact = (src) => {
    api.post('/masters/contact?masterId=' + masterId + '&source=' + src);
  }

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };

  if (!data) {
    return (
      <Container maxWidth="sm" sx={{ mt: 1 }} >
        <Stack direction='row' spacing={1} alignItems='baseline'>
          <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography variant="h5" gutterBottom>Загрузка...</Typography>
        </Stack>
        <LinearProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='baseline'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h5" gutterBottom noWrap>{data.name} {data.lastName || ''}</Typography>
      </Stack>
      <Stack spacing={1} sx={{ mt: 2 }}>
        {error && <Alert severity="error">{error}</Alert>}

        <Card>
          <CardHeader
            avatar={<Avatar
              sx={{ bgcolor: 'primary.light' }}
              src={data.photoUrl}
              alt={data.name + ' ' + (data.lastName || '')} />}
            title={data.name + ' ' + (data.lastName || '')}
          />
          {data.descriptionPhotos?.length > 0 &&
            <Slider {...settings}>
              {[...data.descriptionPhotos].sort((a, b) => a.order - b.order)
                .map((item, index) => (
                  <CardMedia
                    key={index}
                    component="img"
                    image={item.filePath}
                    alt={data.name + ' ' + (data.lastName || '')} />
                ))}
            </Slider>}
          <CardContent>
            <Typography variant="body1" color="text.primary" sx={{ whiteSpace: 'pre-line' }}>
              {data.description || ''}
            </Typography>
            <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
              {data.markTypeIds.map((markTypeId) => (
                <Mark key={markTypeId} markTypeId={markTypeId} />
              ))}
            </Stack>
          </CardContent>
          <CardActions>
            {data.phoneNumber && <Typography>
              <Link onClick={() => contact('profile_phone')} href={`tel:${data.phoneNumber}`} sx={{ mr: 1 }} variant="body1">
                +{data.phoneNumber}
              </Link>
              /
              <Link
                onClick={() => contact('profile_wa')}
                href={`https://wa.me/${data.phoneNumber}?text=Из%20uustar.ru/app/master/${masterId}.%20`}
                sx={{ m: 1 }}
                target="_blank"
                variant="body1">
                WhatsApp
              </Link>
              /
              <Link
                onClick={() => contact('profile_tg')}
                href={`https://t.me/+${data.phoneNumber}?text=Из%20uustar.ru/app/master/${masterId}.%20`}
                sx={{ m: 1 }}
                target="_blank"
                variant="body1">
                Telegram
              </Link>
            </Typography>}
            <Box sx={{ flexGrow: 1 }} />
            <IconButton aria-label="share" onClick={handleShare} >
              <ShareIcon />
            </IconButton>
          </CardActions>
        </Card>

        {data.ratings?.length > 0 ? (
          [...data.ratings]
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((rating) => (
              <Card key={rating.createdAt}>
                <CardHeader
                  avatar={<Avatar
                    sx={{ bgcolor: 'primary.light' }}
                    src={rating.reviewerPhotoUrl}
                    alt={`${rating.reviewerName}`} />}

                  title={`${rating.reviewerName}`}
                  subheader={rating.wouldRecommend ? 'Рекомендую' : undefined}
                  action={<Typography variant="caption" color="text.secondary">
                    {new Date(rating.createdAt).toLocaleDateString()}
                  </Typography>}
                />
                <CardContent>
                  <Typography variant="body1">{rating.comment}</Typography>
                </CardContent>
              </Card>
            ))
        ) : (
          <Typography></Typography>
        )}
      </Stack>
    </Container>
  );
}

export default Master;