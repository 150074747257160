import React, { useEffect, useState } from "react";
import { Box, Container, Stack, Card, CardHeader, Avatar, Typography, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import Mark from "./Mark";

const ActiveMasters = () => {
  const { activeMasters } = useSelector((state) => state.listings);
  const navigate = useNavigate();

  const LOCAL_STORAGE_KEY = "selectedActiveMaster"; // Key to save state in localStorage

  // Initialize state by reading from localStorage or default to 0
  const [selectedIndex, setSelectedIndex] = useState(() => {
    const savedIndex = localStorage.getItem(LOCAL_STORAGE_KEY);
    return savedIndex ? parseInt(savedIndex, 10) : 0;
  });

  useEffect(() => {
    // Sync state with localStorage on mount or when localStorage is updated externally
    const savedIndex = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (savedIndex && parseInt(savedIndex, 10) !== selectedIndex) {
      setSelectedIndex(parseInt(savedIndex, 10));
    }
  }, []);

  const settings = {
    className: "center",
    centerMode: true,
    centerPadding: "12px",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnFocus: true,
    pauseOnDotsHover: true,
    pauseOnHover: true,
    afterChange: function (newValue) {
      setSelectedIndex(newValue);
      localStorage.setItem(LOCAL_STORAGE_KEY, newValue); // Save state to localStorage
    },
    initialSlide: selectedIndex,
    adaptiveHeight: true,
  };

  return (
    <Container maxWidth="sm" sx={{ px: 0 }}>
      <Stack spacing={1}>

        <Slider {...settings}>
          {activeMasters.map((master) => (
            <Box sx={{ paddingX: '4px' }}>
              <Card
                sx={{ position: "relative", width: '100%', height: 184, borderRadius: 3, overflow: "hidden" }}
                onClick={() => navigate(`/master/${master.id}?from=active`)}>

                {master.descriptionPhotos?.find(p => p.order === 0) &&
                  <CardMedia
                    component="img"
                    image={master.descriptionPhotos.find(p => p.order === 0).filePath}
                    alt={master.description}
                    height="100%"
                  />}

                <Box
                  sx={{
                    p: 2,
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0px",
                    left: "0px",
                    color: "white",
                    bgcolor: "rgba(0, 0, 0, 0.5)", // Black tint
                    backdropFilter: "blur(4px)", // Optional glass effect
                    "& .MuiChip-labelSmall": {
                      color: "rgba(255, 255, 255, 0.8)", // Light grayish-white subheader
                    },
                  }}>
                  <CardHeader
                    avatar={<Avatar
                      sx={{ bgcolor: 'primary.light' }}
                      src={master.photoUrl}
                      alt={master.name + ' ' + (master.lastName || '')} />}
                    title={master.name + ' ' + (master.lastName || '')}
                    subheader={(master.ratingsCount > 0
                      ? `Отзывов: ${master.ratingsCount}`
                      : '')}
                    sx={{
                      p: 0,
                      "& .MuiCardHeader-subheader": {
                        color: "rgba(255, 255, 255, 0.8)", // Light grayish-white subheader
                      },
                    }}
                  />
                  <Typography variant="body1" className="lineClamp" sx={{ whiteSpace: 'pre-line', mt: 1 }}>
                    {master.description}
                  </Typography>
                  <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
                    {master.markTypeIds.map((markTypeId) => (
                      <Mark key={markTypeId} markTypeId={markTypeId} />
                    ))}
                  </Stack>
                </Box>
              </Card>
            </Box>
          ))}

        </Slider>
      </Stack>
    </Container>
  );
};

export default ActiveMasters;
