import * as React from 'react';
import { Chip } from '@mui/material';

const Category = ({ categoryId }) => {
  const text = {
    '1': 'Без категории',
    '2': 'Строительство и ремонт',
    '3': 'Сантехники',
    '4': 'Электрики',
    '5': 'Ремонт техники',
    '6': 'Грузчики',
    '7': 'Спецтехника',
    '8': 'Транспортировка',
    '9': 'Автосервис',
    '10': 'Бухгалтерия и финансы',
    '11': 'Детские сады, няни, сиделки',
    '12': 'Красота и здоровье',
    '13': 'Курсы, семинары, тренинги',
    '14': 'Мастер на час',
    '15': 'Мебель, ремонт и изготовление',
    '16': 'Обучение, репетиторство',
    '17': 'Пошив, ремонт одежды, обуви',
    '18': 'Праздники и мероприятия',
    '19': 'Работа с текстом, переводы',
    '20': 'Реклама и полиграфия',
    '21': 'Ремонт компьютеров, мобильных устройств',
    '22': 'Слесари',
    '23': 'Создание сайтов, поддержка ПО',
    '24': 'Уборка помещений, территорий',
    '25': 'Фото- и видеосъёмка',
    '26': 'Юридические услуги',

    '27': 'Ремонт квартир и помещений',
    '28': 'Окна и двери',
    '29': 'Строительство',
    '30': 'Газификация',
    '31': 'Натяжные потолки',
    '32': 'Автоматические ворота',
    '33': 'Сварочные работы',
    '34': 'Отделочные работы',
    '35': 'Сметы и проектирование',
    '36': 'Доставка стройматериалов',
    '37': 'Разморозка домов, гаражей, теплотрасс',
    '38': 'Устранение засоров канализации',
    '39': 'Установка сантехнического оборудования',
    '40': 'Монтаж систем отопления и водоснабжения',
    '41': 'Срочные услуги сантехников и сварщиков',

    '42': 'Уборка территорий',
    '43': 'Вывоз снега',
    '44': 'Уборка помещений, клининг',
    '45': 'Химчистка мебели',
    '46': 'Дезинсекция',
    '47': 'Монтаж систем видеонаблюдения',

    '48': 'Вынос бытового мусора',
  };

  const bgColor = {
    // '1': '#000000',
    '2': '#FF0000',
    '3': '#FF4500',
    '4': '#FF8C00',
    '5': '#FFD700',
    '6': '#ADFF2F',
    '7': '#008000',
    '8': '#006400',
    '9': '#0000FF',
    '10': '#4B0082',
    '11': '#EE82EE',
    '12': '#800080',
    '13': '#FF1493',
    '14': '#FF69B4',
    '15': '#FFA07A',
    '16': '#FF4500',
    '17': '#FF8C00',
    '18': '#FFD700',
    '19': '#ADFF2F',
    '20': '#008000',
    '21': '#006400',
    '22': '#0000FF',
    '23': '#4B0082',
    '24': '#EE82EE',
    '25': '#800080',
    '26': '#FF1493',
    '27': '#FF69B4',
    '28': '#FFA07A',
    '29': '#FF4500',
    '30': '#FF8C00',
    '31': '#FFD700',
    '32': '#ADFF2F',
    '33': '#008000',
    '34': '#006400',
    '35': '#0000FF',
    '36': '#4B0082',
    '37': '#EE82EE',
    '38': '#800080',
    '39': '#FF1493',
    '40': '#FF69B4',
    '41': '#FFA07A',
    '42': '#FF4500',
    '43': '#FF8C00',
    '44': '#FFD700',
    '45': '#ADFF2F',
    '46': '#008000',
    '47': '#006400',
    '48': '#039BE5',
  };

  const fgColor = {
    // '1': '#FFFFFF',
    '2': '#FFFFFF',
    '3': '#FFFFFF',
    '4': '#FFFFFF',
    '5': '#000000',
    '6': '#000000',
    '7': '#FFFFFF',
    '8': '#FFFFFF',
    '9': '#FFFFFF',
    '10': '#FFFFFF',
    '11': '#000000',
    '12': '#FFFFFF',
    '13': '#FFFFFF',
    '14': '#000000',
    '15': '#000000',
    '16': '#FFFFFF',
    '17': '#000000',
    '18': '#000000',
    '19': '#000000',
    '20': '#FFFFFF',
    '21': '#FFFFFF',
    '22': '#FFFFFF',
    '23': '#FFFFFF',
    '24': '#000000',
    '25': '#FFFFFF',
    '26': '#FFFFFF',
    '27': '#FFFFFF',
    '28': '#FFFFFF',
    '29': '#FFFFFF',
    '30': '#FFFFFF',
    '31': '#000000',
    '32': '#000000',
    '33': '#FFFFFF',
    '34': '#FFFFFF',
    '35': '#FFFFFF',
    '36': '#FFFFFF',
    '37': '#FFFFFF',
    '38': '#FFFFFF',
    '39': '#FFFFFF',
    '40': '#FFFFFF',
    '41': '#FFFFFF',
    '42': '#FFFFFF',
    '43': '#FFFFFF',
    '44': '#000000',
    '45': '#000000',
    '46': '#FFFFFF',
    '47': '#FFFFFF',
    '48': '#FFFFFF',
  };

  return (
    <Chip label={text[categoryId]} size="small" sx={{ml: -1, backgroundColor: bgColor[categoryId], color: fgColor[categoryId] }} />
  );
}

export default Category;
