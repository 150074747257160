import { createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { disablePersonalNotifications } from '../services/pushy';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: localStorage.getItem('accessToken'),
    userId: localStorage.getItem('accessToken') && jwtDecode(localStorage.getItem('accessToken')).sub
  },
  reducers: {
    login(state, action) {
      state.token = action.payload.token;
      state.userId = jwtDecode(action.payload.token).sub;
      localStorage.setItem('accessToken', state.token);
    },
    logout(state) {
      disablePersonalNotifications();
      localStorage.removeItem('accessToken');
      state.token = null;
      state.userId = null;
    },
  },
});

export const { login, logout } = authSlice.actions;
export const selectToken = () => localStorage.getItem('accessToken');
export default authSlice.reducer;
