// store.js
import { configureStore } from '@reduxjs/toolkit';
import listingsReducer from './slices/listingsSlice';
import authReducer from './slices/authSlice';

const store = configureStore({
  reducer: {
    listings: listingsReducer,
    auth: authReducer,
  },
});

export default store;
