import { TextField } from '@mui/material';
import React from 'react';
import InputMask from 'react-input-mask';

const PhoneInput = ({ value, onChange, required, inputRef }) => {
  const handleChange = (event) => {
    let inputValue = event.target.value;

    if (!inputValue.startsWith('+7 9')) {
      inputValue = '+7 ' + inputValue.slice(4);
    }

    onChange({
      ...event,
      target: {
        ...event.target,
        value: inputValue,
      },
    });
  };

  return (
    <InputMask
      mask="+7 999 999-99-999"
      maskChar={null}
      value={value}
      onChange={handleChange}
    >
      {(inputProps) => (
        <TextField
          {...inputProps}
          label="Телефон"
          variant="outlined"
          fullWidth
          margin="normal"
          type="tel"
          required={required}
          inputRef={inputRef}
        />
      )}
    </InputMask>
  );
};

export default PhoneInput;
