// pages/InstalliOS.js
import React from 'react';
import { Typography, Container, Stack, IconButton, Box, Link } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const InstallApp = () => {
  const navigate = useNavigate();

  const userAgent = window.navigator.userAgent.toLowerCase();
  const android = /android/.test(userAgent);
  const ios = /iphone|ipad|ipod/.test(userAgent);
  const chrome = /chrome/.test(userAgent) && !/edg|opr|yab|yase|miui/.test(userAgent);
  const samsung = /samsung/.test(userAgent);
  const instagram = /instagram/.test(userAgent);
  const telegram = window.TelegramWebview || window.TelegramWebviewProxy || window.TelegramWebviewProxyProto;

  const iosComponent = (
    <>
      <p>
        <Typography variant="body1">1. Нажмите "Поделиться":</Typography>
        <Box
          component="img"
          src="/static/client/1/ios-share.png"
          alt="iOS Share"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">2. Выберите "На экран &laquo;Домой&raquo;":</Typography>
        <Box
          component="img"
          src="/static/client/1/ios-add-to-home.png"
          alt="iOS Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">3. Нажмите "Добавить":</Typography>
        <Box
          component="img"
          src="/static/client/1/ios-add-to-home-2.png"
          alt="iOS Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">Иконка появится на домашнем экране:</Typography>
        <Box
          component="img"
          src="/static/client/1/ios-screen.png"
          alt="Home screen"
          sx={{ width: '40%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const chromeComponent = (
    <>
      <p>
        <Typography variant="body1">1. Нажмите на меню вверху экрана:</Typography>
        <Box
          component="img"
          src="/static/client/1/chrome-menu.png"
          alt="Chrome Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">2. Выберите "Добавить на гл. экран":</Typography>
        <Box
          component="img"
          src="/static/client/1/chrome-add-to-home.png"
          alt="Chrome Add to Home"
          sx={{ width: '60%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">3. Выберите "Установить":</Typography>
        <Box
          component="img"
          src="/static/client/1/chrome-install.png"
          alt="Chrome install App"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">Иконка появится на домашнем экране:</Typography>
        <Box
          component="img"
          src="/static/client/1/chrome-icon.png"
          alt="Home screen"
          sx={{ width: '30%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const samsungComponent = (
    <>
      <p>
        <Typography variant="body1">1. Нажмите на меню внизу экрана:</Typography>
        <Box
          component="img"
          src="/static/client/1/samsung-menu.png"
          alt="Samsung Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">2. Выберите "Добавить страницу":</Typography>
        <Box
          component="img"
          src="/static/client/1/samsung-add-to-home.png"
          alt="Samsung Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">3. Выберите "Главный экран":</Typography>
        <Box
          component="img"
          src="/static/client/1/samsung-menu-item.png"
          alt="Samsung Add to Home"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">Иконка появится на домашнем экране:</Typography>
        <Box
          component="img"
          src="/static/client/1/samsung-icon.png"
          alt="Home screen"
          sx={{ width: '40%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const telegramIosComponent = (
    <p>
      <Typography variant="body1">Нажмите на кнопку "Открыть в Safari" внизу экрана:</Typography>
      <Box
        component="img"
        src="/static/client/1/tg-ios.png"
        alt="Telegram Safari"
        sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
      />
    </p>
  );

  const telegramAndroidComponent = (
    <>
      <p>
        <Typography variant="body1">1. Нажмите на меню вверху экрана:</Typography>
        <Box
          component="img"
          src="/static/client/1/tg-andr-menu.png"
          alt="Telegram Browser Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">2. Выберите "Открыть в...":</Typography>
        <Box
          component="img"
          src="/static/client/1/tg-andr-open.png"
          alt="Telegram Browser Open"
          sx={{ width: '60%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const instagramIosComponent = (
    <>
      <p>
        <Typography variant="body1">1. Нажмите на меню вверху экрана:</Typography>
        <Box
          component="img"
          src="/static/client/1/ig-ios-menu.png"
          alt="Instagram Browser Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">2. Выберите "Открыть в стороннем браузере":</Typography>
        <Box
          component="img"
          src="/static/client/1/ig-ios-item.png"
          alt="Instagram Browser Open"
          sx={{ width: '60%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const instagramAndroidComponent = (
    <>
      <p>
        <Typography variant="body1">1. Нажмите на меню вверху экрана:</Typography>
        <Box
          component="img"
          src="/static/client/1/ig-android-menu.png"
          alt="Instagram Browser Menu"
          sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
      <p>
        <Typography variant="body1">2. Выберите "Открыть в Chrome" или "Открыть в Samsung Internet":</Typography>
        <Box
          component="img"
          src="/static/client/1/ig-android-item.png"
          alt="Instagram Browser Open"
          sx={{ width: '80%', boxShadow: 2, borderRadius: 1 }}
        />
      </p>
    </>
  );

  const fallbackAndroidComponent = (
    <>
      <p>
        <Typography variant="body1">
          Для работы приложения требуется браузер Google Chrome или Samsung Internet.
        </Typography>
      </p>

      <Stack spacing={2} alignItems="center">
        <Link
          href="https://play.google.com/store/apps/details?id=com.android.chrome"
          target="_blank"
          rel="noopener noreferrer"
          variant="body1"
        >
          <Box component="img" src="/static/google-chrome.jpg" alt="Google Chrome" sx={{ width: "100%", maxWidth: 400, borderRadius: 2 }} />
          <br />
          Установить Google Chrome
        </Link>

        <Link
          href="https://play.google.com/store/apps/details?id=com.sec.android.app.sbrowser"
          target="_blank"
          rel="noopener noreferrer"
          variant="body1"
        >
          <Box component="img" src="/static/samsung-internet.jpg" alt="Samsung Internet" sx={{ width: "100%", maxWidth: 400, borderRadius: 2 }} />
          <br />
          Установить Samsung Internet
        </Link>
      </Stack>
    </>
  );

  const fallbackPcComponent = (
    <p>
      <Typography variant="body1">Нажмите на кнопку "Установить" в адресной строке:</Typography>
      <Box
        component="img"
        src="/static/client/1/pc-chrome.png"
        alt="Chrome"
        sx={{ width: '100%', boxShadow: 2, borderRadius: 1 }}
      />
    </p>
  );

  const component = ios
    ? telegram
      ? telegramIosComponent
      : instagram
        ? instagramIosComponent
        : iosComponent
    : android
      ? telegram
        ? telegramAndroidComponent
        : instagram
          ? instagramAndroidComponent
          : samsung
            ? samsungComponent
            : chrome
              ? chromeComponent
              : fallbackAndroidComponent
      : fallbackPcComponent;

  return (
    <Container maxWidth="sm" sx={{ mt: 1 }}>
      <Stack direction='row' spacing={1} alignItems='baseline'>
        <IconButton color='inherit' size='large' onClick={() => navigate(-1)} aria-label="go back">
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h4" gutterBottom>Установка приложения</Typography>
      </Stack>

      {component}

      <p>
        <Typography variant="body1">
          Если у вас возникли проблемы с установкой приложения, напишите нам в&nbsp;
          <a href="https://t.me/profisfera_ru" target="_blank" rel="noopener noreferrer">
            Telegram
          </a>.
        </Typography>
      </p>

      <p>
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'monospace',
            fontSize: '0.75rem',
          }}>
          {userAgent}
        </Typography>
      </p>
    </Container>
  );
}

export default InstallApp;