import axios from 'axios';
import { logout, selectToken } from '../slices/authSlice';

const api = axios.create({
  baseURL: '/be',
});

// Add a request interceptor to include the token in headers
api.interceptors.request.use(
  (config) => {
    const token = selectToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const setupApiInterceptors = (dispatch) => {
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (error.config && error.config.skipAuthInterceptor) {
          return Promise.reject(error);
        }

        dispatch(logout());
      }
      return Promise.reject(error);
    }
  );
};

export default api;
