// slices/listingsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../services/api';

export const fetchMyListings = createAsyncThunk(
  'listings/fetchMyListings',
  async ({ createdBefore, categoryId }, { rejectWithValue }) => {
    try {
      const response = await api.get('/listings/my', {
        params: {
          createdBefore: createdBefore ?? '',
          categoryId
        }
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchActiveMasters = createAsyncThunk(
  'listings/fetchActiveMasters',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/masters/active');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchListing = createAsyncThunk(
  'listings/fetchListing',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/listings/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  'listings/fetchProfile',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/users/my');

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const listingsSlice = createSlice({
  name: 'listings',
  initialState: {
    activeMasters: [],
    listings: [],
    loading: false,
    reachedEnd: false,
    error: null,
    profile: null,
  },
  reducers: {
    resetListings: (state) => {
      state.listings = [];
      state.reachedEnd = false;
    },
    upsertListing: (state, action) => {
      const updatedListing = action.payload;
      const index = state.listings.findIndex(listing => listing.id === updatedListing.id);

      if (index !== -1) {
        state.listings[index] = updatedListing;
      } else {
        state.listings.unshift(updatedListing);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyListings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyListings.fulfilled, (state, action) => {
        const arr = Array.isArray(action.payload) ? action.payload : [];

        if (arr.length === 0) {
          state.reachedEnd = true;
        }

        state.listings = [...state.listings, ...arr];
        state.loading = false;
      })
      .addCase(fetchMyListings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchListing.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchListing.fulfilled, (state, action) => {
        const updatedListing = action.payload;
        const index = state.listings.findIndex(listing => listing.id === updatedListing.id);

        if (index !== -1) {
          state.listings[index] = updatedListing;
        } else {
          state.listings.unshift(updatedListing);
        }

        state.loading = false;
      })
      .addCase(fetchListing.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchActiveMasters.fulfilled, (state, action) => {
        state.activeMasters = action.payload;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.loading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { upsertListing, resetListings } = listingsSlice.actions;

export default listingsSlice.reducer;
