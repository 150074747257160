// App.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, Link as RouterLink } from 'react-router-dom';
import Navbar from './components/Navbar';
import Listings from './pages/Listings';
import Login from './pages/Login';
import { refreshToken } from './services/pushy';
import Pushy from 'pushy-sdk-web';
import { upsertListing, fetchActiveMasters } from './slices/listingsSlice';
import { Box, createTheme, CssBaseline, Link, ThemeProvider, Typography } from '@mui/material';
import CreateListing from './pages/CreateListing';
import Profile from './pages/Profile';
import ListingDetail from './pages/ListingDetail';
import Master from './pages/Master';
import Privacy from './pages/Privacy';
import InstallApp from './pages/InstallApp';
import EditProfile from './pages/EditProfile';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    if (window.ym) {
      window.ym(process.env.REACT_APP_YANDEX_METR, 'hit', location.pathname);
    }
  }, [location]);

  useEffect(() => {
    dispatch(fetchActiveMasters());

    Pushy.setNotificationListener((data) => {
      console.log('Received notification: ' + JSON.stringify(data));

      if (data.extra) {
        const extra = JSON.parse(data.extra);

        if (extra.listing)
          dispatch(upsertListing(extra.listing));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    refreshToken();
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: '#ff5722',
      },
      secondary: {
        main: '#03a9f4',
        contrastText: '#fff',
      },
    }
  });

  const ProtectedRoute = ({ children }) => token ? children : <Navigate to={`/login?to=${encodeURIComponent(location.pathname + location.search)}`} />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        sx={{ bgcolor: '#ebedef' }}>
        <Box sx={{ flexGrow: 1, pb: token ? '96px' : 2 }}>
          <Routes>
            <Route path="/" element={<ProtectedRoute><Listings /></ProtectedRoute>} />
            <Route path="/create-listing/:id?" element={<ProtectedRoute><CreateListing /></ProtectedRoute>} />
            <Route path="/listing/:id" element={<ListingDetail />} />
            <Route path="/master/:masterId" element={<Master />} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/edit-profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/install-app" element={<InstallApp />} />
          </Routes>
        </Box>

        {
          token
            ? <Navbar />
            : <>
              {/* Footer */}
              <Box sx={{ mt: 'auto', py: 3, textAlign: 'center', backgroundColor: '#333', color: '#fff' }}>
                <Typography variant="body2" color="inherit">
                  ИП Семенов Виктор Петрович. ОГРНИП 322140000027095. ИНН 141901798610.
                </Typography>
                <Typography variant="body2" color="inherit">
                  <Link component={RouterLink} to="/create-listing">
                    Создать&nbsp;новый&nbsp;заказ
                  </Link> | <Link component={RouterLink} to="/login">
                    Войти
                  </Link> | <Link component={RouterLink} to="/privacy">
                    Политика&nbsp;конфиденциальности
                  </Link> | <Link href="https://t.me/profisfera_ru" target="_blank">
                    Наш&nbsp;Телеграм
                  </Link>
                </Typography>
              </Box>
            </>
        }
      </Box>
    </ThemeProvider>
  );
};

export default App;
