import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  TextField,
  Alert,
  Checkbox,
  FormControlLabel,
  CardHeader} from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from 'react-redux';
import api from '../services/api';

const Ratings = ({ listing }) => {
  const { userId } = useSelector((state) => state.auth);
  const [wouldRecommend, setWouldRecommend] = useState(true);
  const [error, setError] = useState(null);
  const [ratingSubmitted, setRatingSubmitted] = useState(false);
  const [comment, setComment] = useState('');

  const handleRatingSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await api.post('/ratings', { listingId: listing.id, wouldRecommend, comment });

      setRatingSubmitted(true);
    } catch (error) {
      setError(error.response?.data || error.message);
    }
  };

  const aboutWho = () => {
    const acceptedOffer = listing.offers?.find(o => o.isAccepted === true);
    return `Про ${acceptedOffer?.name} ${acceptedOffer?.lastName}`;
  }

  return (
    <>
      {userId && (
        <>
          {!ratingSubmitted && (listing.creatorId === userId && listing.hasAcceptedOffer || listing.creatorId !== userId) && (
            <Card>
              <CardHeader title="Оставить отзыв" subheader={aboutWho()} />
              <CardContent>
                <form onSubmit={handleRatingSubmit}>
                  <FormControlLabel
                    control={<Checkbox
                      defaultChecked
                      value={wouldRecommend}
                      onChange={(e) => setWouldRecommend(e.target.checked)} />}
                    label="Рекомендую" />
                  <TextField
                    label="Комментарий"
                    fullWidth
                    margin="normal"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    required
                    multiline
                  />
                  {error && <Alert sx={{ mb: 1 }} severity="error">{error}</Alert>}
                  <Button variant='contained' type="submit">Оставить отзыв</Button>
                </form>
              </CardContent>
            </Card>
          )}
          {ratingSubmitted && <Alert severity="success">Спасибо за отзыв!</Alert>}
        </>
      )}
    </>
  );
}

export default Ratings;