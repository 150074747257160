// pages/Listings.js
import React, { useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyListings, resetListings } from '../slices/listingsSlice';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Card,
  CardContent,
  CardMedia,
  Avatar,
  Alert,
  Container,
  CardHeader,
  CardActionArea,
  LinearProgress,
  IconButton
} from '@mui/material';
import Category from '../components/Category';
import InstallPwa from '../components/InstallPwa';
import CreateListingCard from '../components/CreateListingCard';
import RefreshIcon from '@mui/icons-material/Refresh';
import ListingsBanner from '../components/ListingsBanner';
import ActiveMasters from '../components/ActiveMasters';

const Listings = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listings, loading, reachedEnd, error } = useSelector((state) => state.listings);

  // const [categoryId, setCategoryId] = useState(() => {
  //   return localStorage.getItem('selectedCategoryId') || '';
  // });

  const categoryId = '';

  // const handleCategoryChange = (event) => {
  //   const selectedCategory = event.target.value;
  //   localStorage.setItem('selectedCategoryId', selectedCategory);
  //   setCategoryId(selectedCategory);
  //   dispatch(resetListings());
  // };

  const bottomElementRef = useRef(null);

  const handleScrollEnd = useCallback(() => {
    if (loading) {
      return;
    }

    const createdBefore = listings?.length > 0 ? listings[listings.length - 1].createdAt : undefined;

    dispatch(fetchMyListings({ createdBefore, categoryId }));
  }, [dispatch, listings, loading, categoryId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          handleScrollEnd();
        }
      },
      {
        root: null, // Uses viewport as root
        rootMargin: '0px',
        threshold: 1.0, // Trigger when 100% of the element is visible
      }
    );

    const bottomElementRefCurrent = bottomElementRef.current;

    if (bottomElementRefCurrent) {
      observer.observe(bottomElementRefCurrent);
    }

    return () => {
      if (bottomElementRefCurrent) {
        observer.unobserve(bottomElementRefCurrent);
      }
    };
  }, [handleScrollEnd]);

  return (
    <>
      <InstallPwa />
      <ListingsBanner />
      <ActiveMasters />
      <Container maxWidth="sm" sx={{ mt: 1 }}>
        <Stack spacing={1}>
          {error && <Alert severity="error">{error}</Alert>}

          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography variant="h4" gutterBottom>Мои заказы</Typography>
            <IconButton onClick={() => dispatch(resetListings())}>
              <RefreshIcon />
            </IconButton>
          </Stack>

          {/* <InstallPwaCard /> */}

          {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="category-select-label">Категория</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={categoryId}
              label="Категория"
              onChange={handleCategoryChange}
            >
              <MenuItem value=""><em>Все категории</em></MenuItem>
              <MenuItem value="2">Строительство и ремонт</MenuItem>
              <MenuItem value="3">Сантехники</MenuItem>
              <MenuItem value="4">Электрики</MenuItem>
              <MenuItem value="5">Ремонт техники</MenuItem>
              <MenuItem value="6">Грузчики</MenuItem>
              <MenuItem value="7">Спецтехника</MenuItem>
              <MenuItem value="8">Транспортировка</MenuItem>
              <MenuItem value="9">Автосервис</MenuItem>
              <MenuItem value="10">Бухгалтерия и финансы</MenuItem>
              <MenuItem value="11">Детские сады, няни, сиделки</MenuItem>
              <MenuItem value="12">Красота и здоровье</MenuItem>
              <MenuItem value="13">Курсы, семинары, тренинги</MenuItem>
              <MenuItem value="14">Мастер на час</MenuItem>
              <MenuItem value="15">Мебель, ремонт и изготовление</MenuItem>
              <MenuItem value="16">Обучение, репетиторство</MenuItem>
              <MenuItem value="17">Пошив, ремонт одежды, обуви</MenuItem>
              <MenuItem value="18">Праздники и мероприятия</MenuItem>
              <MenuItem value="19">Работа с текстом, переводы</MenuItem>
              <MenuItem value="20">Реклама и полиграфия</MenuItem>
              <MenuItem value="21">Ремонт компьютеров, мобильных устройств</MenuItem>
              <MenuItem value="22">Слесари</MenuItem>
              <MenuItem value="23">Создание сайтов, поддержка ПО</MenuItem>
              <MenuItem value="24">Уборка помещений, территорий</MenuItem>
              <MenuItem value="25">Фото- и видеосъёмка</MenuItem>
              <MenuItem value="26">Юридические услуги</MenuItem>
              <MenuItem value="1">Без категории</MenuItem>
            </Select>
          </FormControl> */}

          {listings.length === 0 && !loading && <CreateListingCard />}

          {
            listings.map((listing, i) => (
              <>
                <Card
                  key={listing.id}
                  onClick={() => navigate(`/listing/${listing.id}`)}>

                  <CardActionArea>

                    <CardHeader
                      avatar={<Avatar
                        sx={{ bgcolor: 'primary.light' }}
                        src={listing.photoUrl}
                        alt={listing.name}
                      />}

                      title={listing.name}
                      action={<Typography variant="caption" color="text.secondary">
                        {new Date(listing.createdAt).toLocaleDateString()}
                      </Typography>}
                    />

                    {
                      listing.photos?.find(p => p.order === 0) &&
                      <CardMedia
                        component="img"
                        image={listing.photos.find(p => p.order === 0).filePath}
                        alt={listing.description}
                        sx={{ maxHeight: '25vh' }}
                      />
                    }

                    <CardContent>
                      <Typography variant="h6" color="text.primary" className="lineClamp">
                        {listing.title}
                      </Typography>
                      <Category categoryId={listing.categoryId} />
                      <Stack direction={'row'} alignItems="baseline" spacing={1}>
                        {listing.askedPrice && <Typography variant="subtitle1" color="text.primary">
                          {listing.askedPrice} руб.
                        </Typography>}
                        <Typography variant="body2" color="text.secondary">
                          {listing.location}
                        </Typography>
                      </Stack>

                      <Typography variant="body1" color="text.primary" className="lineClamp">
                        {listing.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </>
            ))}

          {!reachedEnd && <LinearProgress ref={bottomElementRef} />}
        </Stack>

      </Container>
    </>
  );
};

export default Listings;
