import * as React from 'react';
import { Chip, Tooltip } from '@mui/material';
import { green, red } from '@mui/material/colors';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Mark = ({ markTypeId }) => {
  const text = {
    '1': 'Паспорт проверен',
    '2': 'В тренде',
  };

  const tooltip = {
    '1': 'Мы проверили копию паспорта: фото, имя и фамилия совпадают с данными в профиле',
    '2': 'Этот мастер часто и быстро откликается на заказы',
  };

  const icon = {
    '1': <VerifiedUserIcon style={{ color: green['A700'] }} />,
    '2': <TrendingUpIcon style={{ color: red['A400'] }} />,
  };

  return (
    <Tooltip title={tooltip[markTypeId]} placement="top">
      <Chip
        icon={icon[markTypeId]}
        label={text[markTypeId]}
        size="small"
        variant='outlined' />
    </Tooltip>
  );
}

export default Mark;
